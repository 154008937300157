import ReactHtmlParser from 'html-react-parser';
import { ReactElement } from 'react';

import { Grid } from '@breathelife/mui';
import { RenderingAgreeField, RenderingField, RenderingOptionField } from '@breathelife/questionnaire-engine';
import { DynamicPdfFieldProps, Language, FieldTypes } from '@breathelife/types';
import { PdfFieldText, PdfPaddedGridItem, PdfQuestionAnswerGroup } from '@breathelife/ui-components';

import { formatTextFieldValue, isUnansweredField, optionIsSelected } from '../../Helpers';

type FieldProps = {
  currency: string;
  field: RenderingField;
  locale: Language;
  fieldOptions: DynamicPdfFieldProps;
};

export function Field(props: FieldProps): ReactElement | null {
  const { currency, field, locale, fieldOptions } = props;

  if (!field.visible || isUnansweredField(field)) {
    return null;
  }

  return FieldFactory(field, locale, currency, fieldOptions);
}

function FieldFactory(
  field: RenderingField,
  locale: Language,
  currency: string,
  fieldOptions: DynamicPdfFieldProps,
): ReactElement | null {
  const options = (field as RenderingOptionField).options;
  const { displayAll, displayLimit = 0, displayFullDate = false } = fieldOptions;

  switch (field.type) {
    case FieldTypes.information:
    case FieldTypes.button:
      return null;
    case FieldTypes.agree:
      return (
        <Grid item xs={12} key={field.id}>
          <PdfQuestionAnswerGroup
            fieldText={(field as RenderingAgreeField).modalText}
            value={field.title || 'I confirm'}
            valueWidth={6}
          />
        </Grid>
      );
    case FieldTypes.dropdown:
    case FieldTypes.radio:
      return (
        <PdfPaddedGridItem item xs={6}>
          {displayAll && field.title && <PdfFieldText>{ReactHtmlParser(field.title)}</PdfFieldText>}
          {options.map((option) => {
            const selectedOption = optionIsSelected(option.id, field.value);

            if (!displayAll && selectedOption) {
              return (
                <PdfQuestionAnswerGroup key={option.id} fieldText={field.title || field.text} value={option.text} />
              );
            }

            if (displayAll) {
              if (options.length <= displayLimit) {
                return (
                  <PdfQuestionAnswerGroup
                    key={option.id}
                    value={option.text}
                    isOptionGroup
                    isUnselectedOption={!selectedOption}
                  />
                );
              } else {
                if (selectedOption) {
                  return <PdfQuestionAnswerGroup key={option.id} value={option.text} />;
                }
              }
            }

            return null;
          })}
        </PdfPaddedGridItem>
      );
    case FieldTypes.checkbox:
    case FieldTypes.checkboxGroup:
      return (
        <PdfPaddedGridItem item xs={6}>
          {displayAll && field.title && <PdfFieldText>{ReactHtmlParser(field.title)}</PdfFieldText>}
          {options.map((option) => {
            const unselectedOption = !optionIsSelected(option.id, field.value);
            if (displayAll && options.length <= displayLimit) {
              return (
                <PdfQuestionAnswerGroup
                  key={option.id}
                  value={option.text}
                  isOptionGroup
                  isUnselectedOption={unselectedOption}
                />
              );
            } else {
              if (unselectedOption) {
                return null;
              }
              return (
                <PdfQuestionAnswerGroup
                  key={option.id}
                  fieldText={field.title || field.text}
                  value={option.text}
                  isOptionGroup
                />
              );
            }
          })}
        </PdfPaddedGridItem>
      );
    // This is handled by the dynamic PDF package, and we don't render this field type with the field-generator
    case FieldTypes.signature: {
      return null;
    }
    default:
      return (
        <PdfPaddedGridItem item xs={6}>
          <PdfQuestionAnswerGroup
            fieldText={field.title || field.text}
            labelText={field.label}
            value={formatTextFieldValue(
              field.value,
              field.type,
              locale,
              currency,
              (field as RenderingOptionField).options,
              displayFullDate,
            )}
          />
        </PdfPaddedGridItem>
      );
  }
}
