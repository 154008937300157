export default {
  yes: 'Oui',
  no: 'Non',
  validation: {
    optional: 'Facultatif',
    radioCheckbox: 'Veuillez répondre à la question',
    agreeField: 'Veuillez lire et accepter le préavis',
  },
  agree: {
    checkbox: "J'accepte",
    modal: {
      agree: 'Accepter',
      cancel: 'Annuler',
    },
    accepted: 'Préavis accepté',
  },
  select: 'Sélectionner',
};
