import { InputVariant } from '@breathelife/ui-components';

import { StyleVariant } from '../Components/FieldGenerator/FieldGenerator';

export function getInputVariant(styleVariant: StyleVariant): InputVariant {
  if (styleVariant === StyleVariant.consumer || styleVariant === StyleVariant.pro) {
    return 'outlined';
  }
  return 'standard';
}
