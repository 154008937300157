import { ReactElement, useMemo } from 'react';

import { isRenderingRepeatedQuestion, OnAnswerChange, RepeatedIndices } from '@breathelife/questionnaire-engine';
import { Language } from '@breathelife/types';

import { BaseFieldProps, StyleVariant } from '../FieldGenerator/FieldGenerator';
import { Question } from './Question/Question';
import { RepeatableQuestion } from './Question/Repeatable/RepeatableQuestion';

export type WebQuestionProps = Omit<BaseFieldProps, 'locale' | 'currency'> & {
  onAnswerChange: OnAnswerChange;
  onAnswerComplete: (fieldId: string, answer: any, previousAnswer: any) => void;
  locale?: Language;
  styleVariant: StyleVariant;
  iconMap?: Record<string, string>;
  onInfoIconClick?: () => void;
  onError?: (fieldId: string, error?: string) => void;
  repeatedIndices?: RepeatedIndices;
};

export function WebQuestion(props: WebQuestionProps): ReactElement | null {
  const {
    question,
    onAnswerChange,
    onAnswerComplete,
    locale,
    styleVariant,
    iconMap,
    onInfoIconClick,
    onError,
    repeatedIndices,
  } = props;

  const repeatableQuestionIndices = useMemo(() => {
    if (isRenderingRepeatedQuestion(question)) {
      return {
        ...repeatedIndices,
        [question.nodeId]: question.metadata.repetitionIndex,
      };
    }
    return repeatedIndices;
  }, [question, repeatedIndices]);

  if (isRenderingRepeatedQuestion(question)) {
    return (
      <RepeatableQuestion
        question={question}
        onAnswerChange={onAnswerChange}
        onAnswerComplete={onAnswerComplete}
        styleVariant={styleVariant}
        locale={locale}
        iconMap={iconMap}
        repeatedIndices={repeatableQuestionIndices || {}}
      />
    );
  }

  return (
    <Question
      question={question}
      repeatedIndices={repeatedIndices}
      onAnswerChange={onAnswerChange}
      onAnswerComplete={onAnswerComplete}
      styleVariant={styleVariant}
      locale={locale}
      iconMap={iconMap}
      onInfoIconClick={onInfoIconClick}
      onError={onError}
    />
  );
}
