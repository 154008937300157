import { Language, LanguageRegion } from '@breathelife/types';

const LanguageRegions: { [lang: string]: LanguageRegion } = {
  [Language.fr]: LanguageRegion.frCa,
  [Language.en]: LanguageRegion.enCa,
};

export function getLanguageRegion(locale: Language): LanguageRegion {
  return LanguageRegions[locale];
}
