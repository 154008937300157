import ReactHtmlParser from 'html-react-parser';
import { ReactElement, Fragment, memo } from 'react';

import { OnAnswerChange, RenderingQuestion, RepeatedIndices } from '@breathelife/questionnaire-engine';
import { Language } from '@breathelife/types';

import { StyleVariant } from '../../FieldGenerator/FieldGenerator';
import { QuestionContent } from './QuestionContent';
import { Container, QuestionText, QuestionTitle } from './Styles';

type QuestionProps = {
  question: RenderingQuestion;
  onAnswerChange: OnAnswerChange;
  onAnswerComplete: (fieldId: string, answer: any, previousAnswer: any) => void;
  styleVariant: StyleVariant;
  repeatedIndices?: RepeatedIndices;
  locale?: Language;
  iconMap?: Record<string, string>;
  onInfoIconClick?: () => void;
  onError?: (fieldId: string, error?: string) => void;
};

export const Question = memo(InnerQuestion);

export function InnerQuestion(props: QuestionProps): ReactElement {
  const {
    question,
    onAnswerChange,
    onAnswerComplete,
    styleVariant,
    locale,
    iconMap,
    onInfoIconClick,
    onError,
    repeatedIndices,
  } = props;

  return (
    <Container styleVariant={styleVariant} key={question.id} data-testid={'question'}>
      <Fragment>
        {question.title && <QuestionTitle styleVariant={styleVariant}>{ReactHtmlParser(question.title)}</QuestionTitle>}
        {question.text && <QuestionText styleVariant={styleVariant}>{ReactHtmlParser(question.text)}</QuestionText>}
        <QuestionContent
          fields={question.fields}
          onAnswerChange={onAnswerChange}
          onAnswerComplete={onAnswerComplete}
          styleVariant={styleVariant}
          repeatedIndices={repeatedIndices}
          locale={locale}
          iconMap={iconMap}
          onInfoIconClick={onInfoIconClick}
          onError={onError}
        />
      </Fragment>
    </Container>
  );
}
