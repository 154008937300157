import I18n, { TranslateOptions } from 'i18n-js';
import _ from 'lodash';

import LocalizedStrings from './';

export function translate(value: string, options?: TranslateOptions): string {
  // Check for existing translations at run time to avoid conflicts.
  I18n.translations = I18n.translations ? _.merge(I18n.translations, LocalizedStrings) : LocalizedStrings;

  return I18n.t(value, options);
}
