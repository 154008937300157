import ReactHtmlParser from 'html-react-parser';
import { ReactElement, Fragment } from 'react';

import { RenderingField } from '@breathelife/questionnaire-engine';
import { FieldSizes, Language, FieldTypes } from '@breathelife/types';

import { formatTextFieldValue, generateCheckboxes, isUnansweredField } from '../../Helpers';
import { translate } from '../../Localization/Localizer';
import { CheckboxGroup } from './CheckboxGroup';
import { FieldContainer, FieldWrapper, Label } from './Styles';
import { TextField } from './TextField';

const MAX_NARROW_FIELD_LENGTH = 80;
const MAX_WIDE_FIELD_LENGTH = 1000;

type FieldProps = {
  currency: string;
  field: RenderingField;
  locale: Language;
};

export function Field(props: FieldProps): ReactElement | null {
  const { currency, field, locale } = props;

  const isInformationField = field.type === FieldTypes.information;
  if (!field.visible || (!isInformationField && isUnansweredField(field))) {
    return null;
  }

  // This is handled by the dynamic PDF package, and we don't render this field type with the field-generator
  if (field.type === FieldTypes.signature) {
    return null;
  }

  const fieldSize = chooseFieldSize(field);
  const optionalLabel = ` (${translate('validation.optional', { locale })})`;
  return (
    <FieldContainer fieldSize={fieldSize}>
      {field.title && <Label>{ReactHtmlParser(field.title)}</Label>}
      {field.text && <Label>{ReactHtmlParser(field.text)}</Label>}
      {field.label && (
        <Label>{`${field.label ? ReactHtmlParser(field.label) : ''}${field.optional ? optionalLabel : ''}`}</Label>
      )}
      <FieldWrapper>{FieldFactory(field, locale, currency)}</FieldWrapper>
    </FieldContainer>
  );
}

function FieldFactory(field: RenderingField, locale: Language, currency: string): ReactElement {
  switch (field.type) {
    case FieldTypes.input:
    case FieldTypes.date:
    case FieldTypes.yearMonth:
    case FieldTypes.phone:
    case FieldTypes.textarea:
    case FieldTypes.number:
    case FieldTypes.money:
    case FieldTypes.autocomplete: {
      return <TextField text={formatTextFieldValue(field.value, field.type, locale, currency)} />;
    }
    case FieldTypes.dropdown:
    case FieldTypes.radio:
    case FieldTypes.checkboxGroup:
    case FieldTypes.checkbox:
    case FieldTypes.agree: {
      return <CheckboxGroup checkboxes={generateCheckboxes(field)} />;
    }
    case FieldTypes.button:
    case FieldTypes.currencyCard:
    case FieldTypes.information:
      return <Fragment />;
    default:
      throw Error('Please specify a type of field supported by the field generator');
  }
}

function chooseFieldSize(field: RenderingField): FieldSizes {
  const maxTextLength: number = getFieldMaxTextLength(field);
  if (maxTextLength > MAX_WIDE_FIELD_LENGTH) return FieldSizes.full;
  if (maxTextLength > MAX_NARROW_FIELD_LENGTH) return FieldSizes.twoThirds;
  return FieldSizes.third;
}

export function getFieldMaxTextLength(field: RenderingField): number {
  const titleLength = field.title ? field.title.length : 0;
  const textLength = field.text ? field.text.length : 0;
  const labelLength = field.label ? field.label.length : 0;
  return Math.max(titleLength, textLength, labelLength);
}
