import { RenderingField, RenderingOptionField } from '@breathelife/questionnaire-engine';

export function fieldIsYesNo(field: RenderingField): boolean {
  const options = (field as RenderingOptionField).options;
  if (!options) return false;

  const hasYes = options.some((option) => option.id === 'yes');
  const hasNo = options.some((option) => option.id === 'no');
  return options.length === 2 && hasYes && hasNo;
}

export function optionIsSelected(optionValue: unknown, fieldValue: unknown | unknown[]): boolean {
  if (Array.isArray(fieldValue)) {
    return fieldValue.includes(optionValue);
  }
  return optionValue === fieldValue;
}
