import styled, { css } from 'styled-components';

import { breakpoints } from '@breathelife/ui-components';

import { BaseStylingProps } from '../../../Styles/BaseStyles';
import { StyleVariant } from '../../FieldGenerator/FieldGenerator';

export const Container = styled.div<BaseStylingProps>`
  ${(props) => {
    switch (props.styleVariant) {
      case StyleVariant.pro:
        return css`
          margin-bottom: 20px;
        `;
      default:
        return css`
          margin-bottom: 20px;
        `;
    }
  }};
`;

export const QuestionText = styled.div<BaseStylingProps>`
  ${(props) => {
    switch (props.styleVariant) {
      case StyleVariant.consumer:
        return css`
          white-space: pre-line;
          line-height: 1.4em;
          font-size: 16px;
          width: 100%;
          margin-bottom: 20px;
          color: ${(props) => props.theme.colors.grey[90]};
          @media (max-width: ${breakpoints.mobile.max}) {
            font-size: 12px;
            margin-bottom: 10px;
          }
        `;
      case StyleVariant.pro:
        return css`
          margin-bottom: 24px;
          font-size: 14px;
        `;
      default:
        throw new Error('Invalid flow variant');
    }
  }};
`;

export const QuestionTitle = styled.h3<BaseStylingProps>`
  ${(props) => {
    switch (props.styleVariant) {
      case StyleVariant.consumer:
        return css`
          width: 100%;
          font-size: 16px;
          margin-bottom: 30px;
          line-height: 1.4em;
          @media (max-width: ${breakpoints.mobile.max}) {
            font-size: 17px !important;
            margin-bottom: 15px !important;
          }

          p {
            margin-bottom: 8px;
          }

          ul,
          ol {
            margin-left: 24px;
            padding-left: 24px;
            margin-top: 16px;
            margin-bottom: 16px;

            li {
              margin-bottom: 8px;
            }
          }

          ul {
            list-style: disc;
          }
        `;
      case StyleVariant.pro:
        return css`
          font-size: 16px;
          margin-bottom: 24px;
          line-height: 1.2;
          display: inline-block;
          width: 100%;
        `;
      default:
        throw new Error('Invalid flow variant');
    }
  }}
`;

type RepeatableQuestionTitleProps = BaseStylingProps & {
  isFirst?: boolean;
};

export const RepeatableQuestionTitle = styled.p<RepeatableQuestionTitleProps>`
  ${(props) => {
    switch (props.styleVariant) {
      case StyleVariant.consumer:
        return css<RepeatableQuestionTitleProps>`
          font-size: 18px;
          font-weight: ${props.theme.fonts.weight.medium};
          margin-top: 20px;
        `;
      case StyleVariant.pro:
        return css<RepeatableQuestionTitleProps>`
          font-weight: 500;
          font-size: 14px;
          letter-spacing: -0.42px;
          margin-top: ${(props) => props.isFirst && '20px'};
          margin-bottom: 0;
        `;
      default:
        throw new Error('Invalid flow variant');
    }
  }}
`;
