import { ReactElement } from 'react';

import { CheckboxData } from '../../Helpers';
import { CheckboxGroupWrap, CheckboxItem, CheckedIcon, UncheckedIcon } from './Styles';

type Props = { checkboxes: CheckboxData[] };

function Checkbox(checkbox: CheckboxData, index: number): ReactElement {
  return (
    <CheckboxItem key={`${checkbox.label}-${index}`}>
      {/*
    A bug in TypeScript 3.6 and up causes a typing issue when used with material-ui icons and
    @types/styled-components 4.1 and up. https://github.com/mui-org/material-ui/issues/17275
    // @ts-ignore */}
      {checkbox.checked ? <CheckedIcon /> : <UncheckedIcon />}
      {checkbox.label}
    </CheckboxItem>
  );
}

export function CheckboxGroup(props: Props): ReactElement {
  return (
    <CheckboxGroupWrap>
      {props.checkboxes.map((checkbox: CheckboxData, index: number) => Checkbox(checkbox, index))}
    </CheckboxGroupWrap>
  );
}
