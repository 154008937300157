import { ReactElement } from 'react';

import { RenderingQuestion } from '@breathelife/questionnaire-engine';
import { Language } from '@breathelife/types';

import { DynamicPdfQuestion, DynamicPdfQuestionProps } from '../DynamicPdfQuestion/DynamicPdfQuestion';
import { PdfQuestion, PdfQuestionProps } from '../PdfQuestion/PdfQuestion';
import { ProSummaryQuestion, ProSummaryQuestionProps } from '../ProSummaryQuestion/ProSummaryQuestion';
import { SummaryQuestion, SummaryQuestionProps } from '../SummaryQuestion/SummaryQuestion';
import { WebQuestion, WebQuestionProps } from '../WebQuestion/WebQuestion';

export enum StyleVariant {
  consumer = 'consumer',
  pro = 'pro',
}

export enum RenderContext {
  // Standard questionnaire form context (device agnostic)
  web = 'web',

  // PDF rendering for a completed questionnaire
  pdf = 'pdf',

  // Dynamic PDF rendering
  dynamicPdf = 'dynamicPdf',

  // Questionnaire step with `summary` variant, typically near the end
  summary = 'summary',

  // Summary of a partial or completed questionnaire inside pro platform
  proSummary = 'proSummary',
}

export type BaseFieldProps = {
  question: RenderingQuestion;
  locale: Language;
  currency: string;
};

type Props = (
  | WebQuestionProps
  | SummaryQuestionProps
  | ProSummaryQuestionProps
  | PdfQuestionProps
  | DynamicPdfQuestionProps
) & {
  context: RenderContext;
};

export function FieldGenerator(props: Props): ReactElement | null {
  const { question } = props;
  if (!question.visible) return null;

  switch (props.context) {
    case RenderContext.web:
      return <WebQuestion {...(props as WebQuestionProps)} />;
    case RenderContext.pdf:
      return <PdfQuestion {...(props as PdfQuestionProps)} />;
    case RenderContext.proSummary:
      return <ProSummaryQuestion {...(props as ProSummaryQuestionProps)} />;
    case RenderContext.summary:
      return <SummaryQuestion {...(props as SummaryQuestionProps)} />;
    case RenderContext.dynamicPdf:
      return <DynamicPdfQuestion {...(props as DynamicPdfQuestionProps)} />;
    default:
      throw new Error('Render context not supported by field generator');
  }
}
