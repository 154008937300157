import styled from 'styled-components';

import { FormControl, FormControlProps, Grid } from '@breathelife/mui';
import { Label, RadioCardContainer } from '@breathelife/ui-components';

export const StyledFormControl = styled(FormControl)<FormControlProps & { inline: boolean }>`
  flex-direction: ${(props) => (props.inline ? 'row !important' : 'column')};
`;

export const FullWidthLabel = styled(Label)`
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const Extra = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const StyledRadioCardContainer = styled(RadioCardContainer)`
  width: 100%;
`;

export const StyledGridItem = styled(Grid)`
  max-width: 50% !important;
`;
