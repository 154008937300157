import { Box } from '@breathelife/mui';
import styled from 'styled-components';

export const CheckboxGroupWrap = styled(Box)`
  color: ${(props) => props.theme.colors.grey[90]};
`;

export const CheckboxWrap = styled(Box)`
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Bold = styled.span`
  font-weight: 500;
`;
