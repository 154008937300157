import styled, { css } from 'styled-components';

import { CheckBoxIcon, CheckBoxOutlineBlankIcon, Box } from '@breathelife/mui';

export const CheckboxGroupWrap = styled(Box)`
  color: ${(props) => props.theme.colors.grey[90]};
`;

const checkboxStyle = css`
  height: 15px !important;
  width: 15px !important;
  vertical-align: top;
  margin: 2px 3px 0 -1px;
`;

export const CheckedIcon = styled(CheckBoxIcon)`
  ${checkboxStyle}
`;

export const UncheckedIcon = styled(CheckBoxOutlineBlankIcon)`
  ${checkboxStyle}
`;

export const Bold = styled.span`
  font-weight: 500;
`;
