import styled from 'styled-components';

import { Button, Checkbox, CheckIcon } from '@breathelife/mui';

export const OpenModalButton = styled(Button)`
  && {
    padding: 10px 20px;
    text-transform: none;
  }
`;

export const AgreedConfirmation = styled.div`
  display: flex;
  align-items: center;
`;

export const HiddenField = styled(Checkbox)`
  && {
    display: none;
  }
`;

export const CheckmarkIcon = styled(CheckIcon)`
  color: #f6f6f6;
`;

export const CheckmarkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.base.success};
  height: 24px;
  width: 24px;
  border-radius: 15px;
  margin-right: 15px;
`;

export const ConfirmationText = styled.p`
  margin: 0;
  font-size: 14px;
`;

export const ModalContent = styled.div`
  padding-bottom: 24px;
  color: ${(props) => props.theme.colors.grey[90]};
`;
