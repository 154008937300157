import _ from 'lodash';
import { ReactElement } from 'react';

import { Grid } from '@breathelife/mui';
import { OnAnswerChange, RenderingField, RepeatedIndices } from '@breathelife/questionnaire-engine';
import { Language } from '@breathelife/types';

import { StyleVariant } from '../../FieldGenerator/FieldGenerator';
import { ReadOnlyField } from '../../ReadOnlyField/ReadOnlyField';
import { Field } from '../Field';

type QuestionContentProps = {
  fields: RenderingField[];
  onAnswerChange: OnAnswerChange;
  onAnswerComplete: (fieldId: string, answer: any, previousAnswer: any) => void;
  styleVariant: StyleVariant;
  repeatedIndices?: RepeatedIndices;
  locale?: Language;
  iconMap?: Record<string, string>;
  onInfoIconClick?: () => void;
  onError?: (fieldId: string, error?: string) => void;
};

export function QuestionContent(props: QuestionContentProps): ReactElement | null {
  const {
    fields,
    onAnswerChange,
    onAnswerComplete,
    styleVariant,
    repeatedIndices,
    locale,
    iconMap,
    onInfoIconClick,
    onError,
  } = props;

  if (_.isEmpty(fields)) return null;

  return (
    <Grid style={{ maxWidth: 'initial' }} container={true} spacing={styleVariant === StyleVariant.consumer ? 2 : 3}>
      {fields.map((field: RenderingField) => {
        const key = `${field.id}${field.appendToKeyValue ? field.appendToKeyValue : ''}`;
        if (field.readOnly) {
          return (
            <ReadOnlyField
              field={field}
              key={key}
              locale={locale}
              isSingleField={fields.length === 1}
              styleVariant={styleVariant}
            />
          );
        }

        return (
          <Field
            key={key}
            field={field}
            onAnswerChange={onAnswerChange}
            onAnswerComplete={onAnswerComplete}
            styleVariant={styleVariant}
            repeatedIndices={repeatedIndices}
            locale={locale}
            iconMap={iconMap}
            onInfoIconClick={onInfoIconClick}
            onError={onError}
          />
        );
      })}
    </Grid>
  );
}
