import ReactHtmlParser from 'html-react-parser';
import { Component, Fragment, ReactElement } from 'react';

import { CloseIcon, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@breathelife/mui';
import { Language } from '@breathelife/types';
import { FieldProps, ModalButton, ModalButtonClose, WarningText } from '@breathelife/ui-components';

import { translate } from '../../../Localization/Localizer';
import {
  AgreedConfirmation,
  CheckmarkContainer,
  CheckmarkIcon,
  ConfirmationText,
  HiddenField,
  ModalContent,
  OpenModalButton,
} from './Styles';

type State = {
  isModalOpen: boolean;
  hasAgreed: boolean;
};

type Props = Omit<FieldProps, 'onAnswerChange'> & {
  label: string | undefined;
  text: string | undefined;
  confirmedLabel: string;
  modalHeader: string;
  modalText: string;
  required?: boolean;
  value?: boolean;
  onAnswerChange?: (answer: boolean) => void;
  onAnswerComplete?: (fieldId: string, answer: boolean, previousAnswer: boolean) => void;
  locale?: Language;
};

export class Agree extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasAgreed: !!props.value,
      isModalOpen: false,
    };
  }

  hasAgreed = (): boolean => !!this.state.hasAgreed;

  handleModalClose = (): void => {
    this.setState({ isModalOpen: false });
  };

  handleModalOpen = (): void => {
    if (!this.props.disabled) {
      this.setState({ isModalOpen: true });
    }
  };

  handleAgreeClick = (): void => {
    this.handleModalClose();
    this.setState({ hasAgreed: true }, () => {
      this.props.onAnswerChange && this.props.onAnswerChange(true);
      this.props.onAnswerComplete && this.props.onAnswerComplete(this.props.name, true, false);
    });
  };

  renderModal = (): ReactElement => (
    <Dialog
      open={this.state.isModalOpen}
      onClose={this.handleModalClose}
      data-testid='modal'
      aria-labelledby={this.props.modalHeader}
    >
      <DialogTitle>
        {this.props.modalHeader ? ReactHtmlParser(this.props.modalHeader) : null}
        <ModalButtonClose data-tracking-name='close-agreement' aria-label='Close' onClick={this.handleModalClose}>
          <CloseIcon />
        </ModalButtonClose>
      </DialogTitle>

      <DialogContent>
        <ModalContent>
          <DialogContentText tabIndex={0}>{ReactHtmlParser(this.props.modalText)}</DialogContentText>
        </ModalContent>
      </DialogContent>

      <DialogActions>
        <ModalButton data-tracking-name='cancel-agreement' variant='outlined' onClick={this.handleModalClose}>
          {translate('agree.modal.cancel', { locale: this.props.locale })}
        </ModalButton>

        <ModalButton
          data-tracking-name='accept-agreement'
          data-testid='accept-agreement'
          variant='contained'
          color='primary'
          onClick={this.handleAgreeClick}
        >
          {ReactHtmlParser(this.props.title || translate('agree.modal.agree', { locale: this.props.locale }))}
        </ModalButton>
      </DialogActions>
    </Dialog>
  );

  renderAgreedConfirmation = (): ReactElement => (
    <AgreedConfirmation>
      <CheckmarkContainer>
        <CheckmarkIcon fontSize='small' />
      </CheckmarkContainer>
      <ConfirmationText data-testid='agree-confirmation'>
        {translate('agree.accepted', { locale: this.props.locale })}
      </ConfirmationText>
    </AgreedConfirmation>
  );

  render(): ReactElement {
    const showError = !!this.props.validationError;
    const modalText = this.props.label || this.props.text;

    return (
      <Fragment>
        {this.state.isModalOpen && this.renderModal()}
        {!this.hasAgreed() && (
          <OpenModalButton
            data-tracking-name='open-agreement'
            variant='outlined'
            disabled={this.props.disabled}
            onClick={this.handleModalOpen}
          >
            {modalText ? ReactHtmlParser(modalText) : null}
          </OpenModalButton>
        )}
        {showError && <WarningText>{translate('validation.agreeField', { locale: this.props.locale })}</WarningText>}
        {this.hasAgreed() && this.renderAgreedConfirmation()}
        <HiddenField
          checked={this.state.hasAgreed}
          name={this.props.name}
          disabled={this.props.disabled}
          color='secondary'
        />
      </Fragment>
    );
  }
}
