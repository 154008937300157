export default {
  yes: 'Yes',
  no: 'No',
  validation: {
    optional: 'Optional',
    radioCheckbox: 'Please answer the question',
    agreeField: 'Please read and accept the notice',
  },
  agree: {
    checkbox: 'I agree',
    modal: {
      agree: 'Agree',
      cancel: 'Cancel',
    },
    accepted: 'Terms accepted',
  },
  select: 'Select',
};
