import ReactHtmlParser from 'html-react-parser';
import { ReactElement, Fragment } from 'react';

import { Box, Grid } from '@breathelife/mui';

import { RenderingField } from '@breathelife/questionnaire-engine';
import { DynamicPdfFieldProps } from '@breathelife/types';
import { PdfFieldText } from '@breathelife/ui-components';

import { BaseFieldProps } from '../FieldGenerator/FieldGenerator';
import { Field } from './Field';

export type DynamicPdfQuestionProps = BaseFieldProps & { fieldOptions: DynamicPdfFieldProps };

export function DynamicPdfQuestion(props: DynamicPdfQuestionProps): ReactElement | null {
  const { currency, locale, question, fieldOptions } = props;

  return (
    <Fragment>
      {question.title && (
        <Box mb={2} mt={-1}>
          <PdfFieldText>{ReactHtmlParser(question.title)}</PdfFieldText>
        </Box>
      )}

      <Grid container spacing={3} alignItems={fieldOptions.displayAll ? 'flex-start' : 'flex-end'}>
        {question.fields.map((field: RenderingField) => (
          <Fragment key={field.id}>
            <Field currency={currency} field={field} locale={locale} fieldOptions={fieldOptions} />
          </Fragment>
        ))}
      </Grid>
    </Fragment>
  );
}
