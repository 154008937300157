import { ReactElement } from 'react';
import styled, { css } from 'styled-components';

import { Grid } from '@breathelife/mui';
import { FieldSizes } from '@breathelife/types';

import { BaseStylingProps } from '../../Styles/BaseStyles';
import { StyleVariant } from '../FieldGenerator/FieldGenerator';

const StyledGrid = styled(Grid)<{ $styleVariant: StyleVariant }>`
  ${(props) => {
    if (props.$styleVariant === StyleVariant.pro) {
      return css`
        &&& {
          padding: unset;
        }
      `;
    }
  }}
`;

export function SpacerField(props: BaseStylingProps): ReactElement {
  return <StyledGrid item xs={FieldSizes.full} $styleVariant={props.styleVariant} />;
}
